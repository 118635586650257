<template>
  <div v-if="items" class="blocks" :class="classObject">
    <component :is="getBlockComponent(item.entity.bundle)" v-for="item in items" :key="item.entity.key" :data="item.entity" />
  </div>
</template>

<script setup lang="ts">
const { getBlockComponent } = useGetBlockComponent()

export interface Props {
  items: Array
  modifier?: string
}

const props = withDefaults(defineProps<Props>(), {
  items: () => [],
  modifier: ''
})

const classObject = {
  [`blocks--${props.modifier}`]: props.modifier.value !== ''
}
</script>

<style lang="scss">
@use "~/assets/sass/tools";

.blocks {
  @include tools.grid;

  &--wide {
    >* {
      @include tools.page-grid-columns-wide($important: true);
    }
  }

  >*:first-child {
    margin-top: 0 !important;
  }

  >.heading--level-1:first-child + .heading {
    margin-top: 0 !important;
  }
}
</style>
